<template>
  <moe-page title="设备日志">
    <moe-inquire @search="deviceLogSearch" :isReset="false">
      <el-form-item label="设备ID">
        <el-input :value="deviceParams.deviceId" @input="(value) => deviceParams.deviceId = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入设备ID" clearable />
      </el-form-item>
      <el-form-item label="用户ID">
        <el-input :value="deviceParams.userId" @input="(value) => deviceParams.userId = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入用户ID" clearable />
      </el-form-item>
      <el-form-item label="设备序列号">
        <el-input v-model="deviceParams.serial" placeholder="请输入设备序列号" clearable />
      </el-form-item>
      <el-form-item label="设备uid">
        <el-input v-model="deviceParams.uid" placeholder="请输入设备uid" clearable />
      </el-form-item>
      <el-form-item label="用户手机号">
        <el-input v-model="deviceParams.phone" placeholder="请输入用户手机号" clearable />
      </el-form-item>
    </moe-inquire>

    <div class="df1 df fdc">
      <moe-table ref="deviceLogTable" url="/device/log/page" :params="deviceParams" :number-show="false" :mode="mode">
        <el-table-column prop="id" label="序号" width="100" />
        <el-table-column prop="deviceId" label="设备ID" width="100" />
        <el-table-column prop="userId" label="用户ID" width="100" />
        <el-table-column prop="phone" label="用户手机号" width="120" />
        <el-table-column prop="serial" label="设备序列号" width="100" :show-overflow-tooltip="true" />
        <el-table-column prop="uid" label="设备uid" :show-overflow-tooltip="true" />
        <el-table-column prop="logType" label="日志类型" width="100" />
        <el-table-column label="备注" prop="remark" :show-overflow-tooltip="true" min-width="600" />
        <el-table-column prop="logData" label="日志内容" :show-overflow-tooltip="true" min-width="150" />
        <el-table-column prop="createTime" label="创建时间" width="150">
          <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.createTime) }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="事件时间" width="150">
          <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.logTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <div class="moe-table__btns" slot-scope="{ row }">
            <el-button type="primary" size="mini" icon="el-icon-view" @click="handleDetail(row)">查看日志内容</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </div>

    <moe-dialog :autoHeight="true" :show="showDialog" title="查看日志内容" width="50%" @close="handleDialogClose()">
      <div class="dialog_content">
        {{ logData }}
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'DeviceLog',
  data() {
    return {
      deviceParams: {
        pageNum: 1,   //当前页
        pageSize: 10, //每页条数
        deviceId: '', //设备ID
        userId: '',   //用户ID
        serial: '',   //序列号
        uid: '',      //uid
        phone: '',    //用户手机号
      },
      mode: false,
      showDialog: false,
      logData: '',
    }
  },
  mounted() {
  },
  methods: {
    deviceLogSearch(isSearch) {
      if (!this.deviceParams.deviceId && !this.deviceParams.userId && !this.deviceParams.serial && !this.deviceParams.uid && !this.deviceParams.phone) {
        return this.$moe_msg.warning('请输入内容')
      }
      this.mode = true;

      if (!isSearch) {
        this.deviceParams = {
          pageNum: 1,
          pageSize: 10,
          deviceId: '',
          userId: '',
          serial: '',
          uid: '',
        }
      }

      this.$refs['deviceLogTable'].searchData();
    },
    handleDetail({ logData }) {
      this.logData = logData;
      this.showDialog = true;
    },
    handleDialogClose() {
      this.showDialog = false;
    },
  }
}
</script>